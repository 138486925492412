<template>
  <transition name="fade">
    <!-- No files -->

    <div v-if="!fileUploadedStasus && !hasError" class="uploader">
      <input
        accept=".csv"
        max-file-size="1"
        name="files"
        ref="input"
        class="d-none"
        type="file"
        @change="onChange($event)"
      />
      <div
        class="zone"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.stop.prevent="onDrop"
      >
        <div class="placeholder" :class="{ 'dragged-over': isDraggedOver }">
          <h6 class="mb-4">CSV-Datei per Drag & Drop hinzufügen</h6>
          <ph-file-arrow-up
            class="my-5"
            :size="72"
            weight="thin"
            color="#909090"
            :class="{ animated: isDraggedOver }"
          />
          <p class="mt-2">oder eine Datei auswählen</p>
          <button class="btn btn-primary my-4" @click="chooseFile">
            <ph-plus :size="18" weight="bold" />
            Datei auswählen
          </button>
          <p class="hint">Es sind nur CSV Dateien erlaubt.</p>
        </div>
      </div>
    </div>

    <!-- File uploading -->

    <div v-if="fileUploadedStasus && !hasError" class="uploader">
      <cancel-upload-file
        v-if="openModal"
        @close="openModal = false"
        @cancel="cancelUpload"
      />
      <div class="zone">
        <div class="placeholder" :class="{ 'dragged-over': isDraggedOver }">
          <div
            class="placeholder-loader-fill"
            :style="{ height: percent + '%' }"
          ></div>
          <h6 class="mb-4">Ihre Datei wird hochgeladen…</h6>
          <p class="mt-5 bold text-primary font-medium">{{ percent }}%</p>
          <ph-file-arrow-up
            class="mt-3"
            :size="72"
            weight="thin"
            color="#909090"
          />
          <div class="mt-3 mb-5 text-center">
            <p v-if="getFileInfo.name" class="bold">{{ getFileInfo.name }}</p>
            <p v-if="getFileInfo.size" class="hint mt-2">
              Dateigröße: {{ getFileInfo.size }} mb
            </p>
          </div>

          <div
            v-if="percent < 100"
            class="d-flex justify-content-end align-items-center mt-5"
          >
            <ph-x-circle :size="18" weight="bold" color="#909090" />
            <p class="link hint m-1" @click="openModal = true">Abbrechen</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Error -->

    <div v-if="hasError" class="uploader">
      <div class="zone">
        <div class="placeholder" :class="{ 'dragged-over': isDraggedOver }">
          <h6 class="mb-4 bold text-red">{{ errorText }}</h6>
          <ph-file-arrow-up
            class="mt-3"
            :size="72"
            weight="thin"
            color="#909090"
          />
          <div class="my-3 text-center">
            <p>{{ fileName }}</p>
          </div>
          <p class="hint">{{ errorTextDescription }}</p>
          <p class="mt-3 link text-primary font-small" @click="goBack">
            Erneut versuchen
          </p>
          <button class="btn btn-primary mt-5" @click="goBack">
            <ph-arrow-left :size="18" weight="bold" />
            ZURÜCK
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import * as CSV from "csv-string";
import CancelUploadFile from "./Modals/CancelUploadFile.vue";

export default {
  components: { CancelUploadFile },
  props: {
    percent: {
      type: Number,
      required: true,
      default: 0
    }
  },
  name: "Uploader",
  data() {
    return {
      dragStart: false,
      openModal: false,
      isDraggedOver: false,
      localFiles: [],
      multiple: false,
      accept: "text/csv",
      fileIsUploading: false,
      hasError: false,
      errorText: "",
      errorTextDescription: "",
      fileHeader: []
    };
  },
  computed: {
    ...mapGetters(["getFileInfo"]),
    fileUploadedStasus() {
      return this.$store.state.fileInfo.status;
    },

    fileName() {
      return this.localFiles.name;
    },
    fileSize() {
      return (this.localFiles.size / (1024 * 1024)).toFixed(1);
    }
  },
  methods: {
    ...mapMutations(["setFileInfo"]),
    addLocalFiles(fileList) {
      const files = Array.from(fileList);
      this.localFiles = files[0];
      this.readFile(files[0]);
    },

    onDragOver() {
      this.dragStart = true;
      this.isDraggedOver = true;
    },

    onDragLeave() {
      this.dragStart = false;
      this.isDraggedOver = false;
    },

    onDrop(e) {
      const fileList = e.dataTransfer.files;
      console.log(e);
      let name = fileList[0].name;

      const fileExt = name.split(".").pop();
      if (fileExt !== "csv") {
        this.hasError = true;
        this.errorText = "Nicht zulässiges Dateiformat";
        return (this.errorTextDescription = "Nicht zulässiges Dateiformat");
      }
      if (fileList) {
        this.addLocalFiles(fileList);
      }
      this.onDragLeave();
    },

    onChange(e) {
      const fileList = e.target.files;

      let name = fileList[0].name;
      const fileExt = name.split(".").pop();
      if (fileExt !== "csv") {
        this.hasError = true;
        this.errorText = "Nicht zulässiges Dateiformat";
        return (this.errorTextDescription = "Nicht zulässiges Dateiformat");
      }
      if (fileList) this.addLocalFiles(fileList);
    },

    chooseFile() {
      this.$refs.input.click();
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },

    readFile(file) {
      const separators = [];
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = e => {
        let text = e.target.result;
        try {
          let firstLine = text.split("\n").shift();
          let fileSeperator = CSV.detect(firstLine);
          if (
            fileSeperator !== "," &&
            fileSeperator !== ";" &&
            fileSeperator !== "\t"
          ) {
            this.hasError = true;
            this.errorText = "Nicht zulässige CSV Separatoren";
            return (this.errorTextDescription =
              "Nicht zulässige CSV Separatoren");
          }
          separators.push(fileSeperator);
          firstLine = firstLine.replace(/(?:\r\n|\r|\n)/g, "");

          this.fileHeader = firstLine.split(
            new RegExp(separators.join("|"), "g")
          );

          let clear = this.fileHeader.map(item =>
            item.replace(/(?:\r\n|\r|\n)/g, "")
          );
          let x = clear.map(item => item.replace(/"/g, ""));
          this.fileHeader = x.filter(this.onlyUnique);
          console.log(this.fileHeader);
          this.$store.commit("setWizardFields", this.fileHeader);
          const fileInfo = {
            name: this.fileName,
            size: this.fileSize,
            status: true
          };
          this.$store.commit("setFileInfo", fileInfo);
          this.$store.commit("setWizardFields", this.fileHeader);
          this.uploadFiles(fileSeperator);
        } catch (e) {
          this.hasError = true;
          this.errorText = "Data Error";
          this.errorTextDescription = "File is not readable";
          console.error(e);
        }
      };
      reader.onerror = e => {
        this.errorText = "Data Error";
        this.errorTextDescription = "File is not readable";
        console.error(e);
      };
    },

    uploadFiles(seperator) {
      const data = {
        seperator,
        file: this.localFiles
      };
      this.fileIsUploading = true;
      this.$emit("fileUploaded", data);
      //
    },

    cancelUpload() {
      this.$emit("cancelReq");
      this.clear();
      //
    },
    goBack() {
      this.cancelUpload();
    },
    setError(val) {
      this.hasError = val.errStatus;
      this.errorText = val.errTitle;
      this.errorTextDescription = val.errDesc;
    },

    clear() {
      this.isDraggedOver = false;
      this.dragStart = false;
      this.localFiles = [];
      this.fileIsUploading = false;
      this.$emit("update:percent", 0);
      const fileInfo = {
        name: null,
        size: null,
        status: false
      };
      this.$store.commit("setFileInfo", fileInfo);
      this.hasError = false;
      this.errorText = "";
      this.errorTextDescription = "";
      this.fileHeader = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.uploader {
  min-height: 540px;

  input[type="file"] {
    display: none;
  }

  .zone {
    min-width: 478px;
    max-width: 478px;
    margin: -1px;
    background: #fbfbfb;
    border-radius: 20px;
  }

  .placeholder {
    z-index: 2;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 65px 95px;
    border: 1px dashed #909090;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    position: relative;

    .placeholder-loader-fill {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background: #f5f5f5;
      border-radius: 20px;
    }

    &:hover,
    &.draged-over {
      border-color: #a7b6cf;
    }

    &:active {
      border-color: #7d91b2;
    }
  }

  h6 {
    font-size: 30px;
    font-weight: normal;
    text-align: center;
  }

  p {
    font-size: 18px;
  }
  p.hint {
    font-size: 14px;
    opacity: 0.5;
  }
}
</style>
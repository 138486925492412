<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container text" v-click-outside="onClickOutside">
          <div class="d-flex justify-content-end align-items-center">
            <ph-x
              @click="$emit('close')"
              :size="24"
              weight="bold"
              class="pointer"
            />
          </div>

          <div class="modal-header">
            <h3>Support</h3>
          </div>

          <div class="relative">
            <div
              v-if="loading"
              class="loading-wrapper absolute w-100 bg-white h-100 z-index-1"
            >
              <div class="loader"></div>
            </div>
            <div class="modal-body relative">
              <div class="text-red mb-2" v-if="emptyField">
                * Bitte Ihr Anliegen genau beschreiben.
              </div>
              <div class="text-green mb-2" v-if="isSuccess">
                Vielen Dank für Ihre Anfrage. Sie werden in Kürze kontaktiert.
              </div>
              <div v-if="!isSuccess">
                <input
                  v-model.trim="form.title"
                  class="form-control mb-3"
                  type="text"
                  placeholder="Betreff"
                />
                <textarea
                  v-model.trim="form.body"
                  class="form-control"
                  rows="6"
                  placeholder="Text *"
                ></textarea>
                <p class="m-3">* Bitte Ihr Anliegen genau beschreiben.</p>
              </div>

              <div v-if="!isSuccess" class="modal-footer pt-4">
                <button
                  v-if="!loading"
                  class="action btn btn-primary"
                  @click="sendTicket"
                >
                  Absenden
                </button>
                <span class="link ml-4" @click="$emit('close')">Abbrechen</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from "v-click-outside";
import API_ROUTES from "@/utils/API_ROUTES.js";
export default {
  name: "SupportModal",
  props: {
    shownModal: Boolean,
  },
  data() {
    return {
      showModal: this.shownModal,
      emptyField: false,
      loading: false,
      isSuccess: false,
      form: {
        title: null,
        body: null,
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    clearHTML(str) {
      if (!str) {
        return str;
      }
      let i = str.length,
        aRet = [];

      while (i--) {
        var iC = str.charCodeAt(i);
        if (iC < 65 || iC > 127 || (iC > 90 && iC < 97)) {
          aRet[i] = "&#" + iC + ";";
        } else {
          aRet[i] = str[i];
        }
      }
      return aRet.join("");
    },
    async sendTicket() {
      try {
        this.emptyField = false;
        this.isSuccess = false;
        if (!this.form.body) {
          return (this.emptyField = true);
        }
        this.loading = true;
        let data = {
          subject: this.clearHTML(this.form.title),
          message: this.clearHTML(this.form.body),
        };
        let response = await this.$axios.post(
          API_ROUTES.sendSupportMessage(),
          data
        );
        if (response.status === "success") {
          this.isSuccess = true;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  * {
    font-family: Barlow;
  }
}

.modal-container {
  width: 650px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
  border-radius: 5px;
}

.modal-header h3 {
  margin-top: 0;
  font-family: Barlow Condensed;
}

.modal-body {
  margin: 20px 0;

  p {
    font-size: 14px;
  }

  input {
    padding: 10px;
  }

  textarea {
    resize: vertical;
  }

  textarea::-webkit-resizer {
    background-size: contain;
    background-image: url(../assets/icons/Resizer.svg);
  }

  .form-control::placeholder {
    font-size: 16px;
    color: #909090;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
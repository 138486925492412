<template>
  <div class="wizard col-12">
    <div class="d-flex flex-column align-items-start">
      <h3 class="pl-4 pt-4 pb-2">Daten importieren</h3>
      <wizard-alert-modal
        v-if="openModal"
        @close="toggleModal"
        :shownModal="openModal"
      />
      <p class="pl-4 pb-5">
        Ordnen Sie hier die passenden Spaltennamen aus Ihrer CSV Datei zu. Alle
        Markierungen mit * sind Pflichtfelder.
      </p>
    </div>

    <div class="data-container">
      <div v-for="(item, index) in wizardContent" :key="index" class="data">
        <h4>{{ item.title }} <span v-if="item.required">*</span></h4>
        <div style="width: 26%" class="d-flex justify-content-end">
          <ph-arrow-right :size="24" color="#909090" weight="bold" />
        </div>
        <div style="width: 37%" class="d-flex justify-content-end">
          <v-select
            label="val"
            :data-id="index"
            class="vme-select"
            :clearable="false"
            :searchable="false"
            placeholder="auswählen"
            @open="selectedWizardValue(item)"
            @option:clear="deSelectWizardValue(item)"
            :options="fileHeaderFilter"
            v-model="item.val"
          >
            <template #selected-option="{ val }">
              <span
                :class="
                  val !== 'auswählen'
                    ? 'vs__selected pl-0'
                    : 'vs__search h-auto pl-0 font-semibold border-0'
                "
                >{{ val }}</span
              >
            </template>
            <template #option="{ val }">
              <span>{{ val }}</span>
            </template>
            <div slot="no-options">keine Auswahlmöglichkeit</div>
          </v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import _ from "lodash";
const WizardAlertModal = () =>
  import("@/components/Modals/WizardAlertModal.vue");
export default {
  props: ["wizardFields"],
  components: {
    WizardAlertModal
  },
  data() {
    return {
      options: this.wizardFields,
      openModal: false,
      wizard: [
        {
          title: "EAN",
          val: "",
          key: "EAN",
          required: true
        },
        {
          title: "VAN-VERBANDSARTIKELNUMMER",
          val: "",
          key: "Verbandsnummer",
          required: true
        },
        {
          title: "ARTIKELNUMMER DES HERSTELLERS",
          val: "",
          key: "HerstellerArtikelNummer",
          required: false
        },
        {
          title: "INTERNE ID IHRER WARENWIRTSCHAFT",
          val: "",
          key: "InterneID",
          required: false
        },
        {
          title: "LIEFERANTENNUMMER",
          val: "",
          key: "LieferantenNummer",
          required: false
        },
        {
          title: "LIEFERANTENNAME",
          val: "",
          key: "LieferantenName",
          required: false
        },
        {
          title: "WARENGRUPPE",
          val: "",
          key: "Warengruppe",
          required: false
        },
        {
          title: "TEXT ZUM MODELL",
          val: "",
          key: "Modelltext",
          required: false
        },

        {
          title: "ARTIKELNAME DES HERSTELLERS",
          val: "",
          key: "HerstellerArtikelName",
          required: false
        },
        {
          title: "MODELLNAME DES HERSTELLERS",
          val: "",
          key: "HerstellerModellName",
          required: false
        },
        {
          title: "MODELLNUMMER DES HERSTELLERS",
          val: "",
          key: "HerstellerModellNummer",
          required: false
        },
        {
          title: "TYPNAME DES HERSTELLERS",
          val: "",
          key: "HerstellerTypName",
          required: false
        },
        {
          title: "AUSPRÄGUNGSNAME DES HERSTELLERS",
          val: "",
          key: "HerstellerAusprägungsName",
          required: false
        },
        {
          title: "TEXT ZUR AUSPRÄGUNG",
          val: "",
          key: "AusprägungsText",
          required: false
        },
        {
          title: "ETIKETTENREGEL1",
          val: "",
          key: "Etikettenregel1",
          required: false
        },
        {
          title: "ETIKETTENREGEL2",
          val: "",
          key: "Etikettenregel2",
          required: false
        },
        {
          title: "ETIKETTENREGEL3",
          val: "",
          key: "Etikettenregel3",
          required: false
        }
      ],
      wizardData: {
        LieferantenNummer: "",
        LieferantenName: "",
        Warengruppe: "",
        Modelltext: "",
        Verbandsnummer: "",
        EAN: ""
      },
      selectedVal: [],
      tmp: []
    };
  },
  computed: {
    wizardContent() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.wizard.sort(this.SortArray).reverse();
    },
    fileHeaderFilter() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.options = ["auswählen", ...this.options];
      return this.options;
    }
  },
  methods: {
    deSelectWizardValue(item) {
      if (!item.val || item.val.length === 0) {
        this.selectedVal = this.selectedVal.filter(x => x.key !== item.key);
      }
    },
    selectedWizardValue(item) {
      console.log(item);
      let isHaveKey = this.selectedVal.findIndex(el => el.key === item.key);
      if (isHaveKey !== -1) {
        this.selectedVal[isHaveKey].val = item.val;
      } else {
        this.selectedVal.push(item);
      }
      let x = [];
      for (let item of this.selectedVal) {
        for (let y of this.wizardFields) {
          if (item.val === y) {
            x.push(y);
          }
        }
      }
      let difference = this.wizardFields
        .filter(z => !x.includes(z))
        .concat(x.filter(t => !this.wizardFields.includes(t)));
      this.options = difference;
      console.log(this.selectedVal);
    },
    SortArray(a, b) {
      if (a.required || b.required) {
        return -1;
      }
      if (a.title > b.title) {
        return -1;
      }
    },
    toggleModal() {
      this.openModal = !this.openModal;
    },
    checkMap() {
      const isRequiredMapping = this.wizardContent.findIndex(
        item =>
          (item.required && item.val === "") ||
          (item.required && item.val === null) ||
          (item.required && item.val === "auswählen")
      );
      return isRequiredMapping;
    },
    completeMapping() {
      const isComplete = this.checkMap();
      if (isComplete !== -1) {
        this.toggleModal(false);
        return false;
      }
      return this.wizardContent.filter(item => item.val !== "auswählen");
    }
  }
};
</script>

<style lang="scss" scoped>
.wizard {
  .data-container {
    overflow: auto;
    max-height: 70vh;

    @media (min-height: 100px) and (max-height: 910px) {
      max-height: 56vh;
    }
  }
  .data {
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 40px 50px;

    h4 {
      width: 30%;
    }

    &:last-child {
      margin-bottom: 100px;
    }
  }

  .v-select {
    min-width: 225px;
    width: auto;
  }
}
</style>


<style>
.vme-select .vs__search::placeholder {
  height: 48px;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
}

.vme-select .vs__selected {
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
}

.vme-select .vs__dropdown-option {
  font-family: Barlow;
}

.vme-select .vs__search,
.vme-select .vs__selected {
  height: 48px;
  padding-left: 20px;
}

.vme-select .vs__dropdown-toggle,
.vme-select .vs__dropdown-menu {
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 10px;
}

.vme-select .vs__clear {
  display: block;
}

.vme-select .vs__clear,
.vme-select .vs__open-indicator {
  stroke: #ee7f00;
  fill: #ffffff;
  margin-right: 20px;
}
</style>
<template>
  <div class="sidebar overflow-auto d-flex flex-column">
    <div class="flex-grow-1">
      <img class="logo" src="@/assets/icons/Logo.svg" />
      <div class="divider"></div>
      <h5>MENÜ</h5>
      <div class="menu">
        <div
          v-for="(route, index) in menuRoutes"
          :key="index"
          class="menu-item"
          @click="changeRoute(route)"
          :class="{ active: currentRoute == route.name }"
        >
          <component :is="route.icon" :size="26" weight="bold" />
          <span>{{ route.title }}</span>
        </div>
      </div>
      <h5>Support</h5>
      <div class="menu">
        <div
          v-for="(route, index) in supportRoutes"
          :key="index"
          class="menu-item"
          @click="changeRoute(route)"
          :class="{ active: currentRoute === route.name }"
        >
          <component :is="route.icon" :size="26" weight="bold" />
          <span>{{ route.title }}</span>
        </div>

        <div @click="showModal = true" class="menu-item">
          <ph-chats :size="26" weight="bold" />
          <span>Unterstützung</span>
        </div>
      </div>
    </div>
    <div>
      <div class="divider"></div>
      <div @click="openUserMenu" class="user-menu pt-4">
        <div
          class="
            user-menu-button
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <img class="user-icon" src="@/assets/icons/User.svg" />
          <p>{{ userName }}</p>
          <svg
            class="arrow"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M7 10.0547L4.05455 13.0001L1.10909 10.0547"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 4.51807L4.05455 1.57261L1.10909 4.51807"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <div
          v-if="userMenuOpen"
          v-click-outside="onClickOutside"
          class="user-menu-popup d-flex align-items-start flex-column"
        >
          <img class="user-icon" src="@/assets/icons/User.svg" />
          <p class="user-name pt-5 ellipsis-text">{{ userFullName }}</p>
          <p class="py-4 ellipsis-text">{{ userEmail }}</p>
          <div
            class="
              mt-auto
              d-flex
              justify-content-between
              align-items-center
              sign-out
            "
          >
            <div
              @click="logOut"
              class="d-flex justify-content-start align-items-center"
            >
              <ph-sign-out :size="18" weight="bold" color="#EE7F00" />
              <span class="link pl-1">Abmelden</span>
            </div>
            <svg
              @click="closeUserMenu"
              class="arrow"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M7 10.0547L4.05455 13.0001L1.10909 10.0547"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 4.51807L4.05455 1.57261L1.10909 4.51807"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <SupportModal v-if="showModal" @close="showModal = false" />
    <TutorialModal v-if="openTutorial" @close="openTutorial = false" />
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import SupportModal from "@/components/SupportModal.vue";
import TutorialModal from "@/components/Modals/Tutorial.vue";
export default {
  components: {
    SupportModal,
    TutorialModal
  },
  data() {
    return {
      openTutorial: false,
      menuRoutes: [
        {
          name: "Home",
          title: "Neuer Import",
          path: "/",
          icon: "ph-file-plus"
        },
        {
          name: "Processing",
          title: "In Bearbeitung",
          path: "/processing",
          icon: "ph-spinner-gap"
        },
        {
          name: "History",
          title: "Ergebnisse",
          path: "/history",
          icon: "ph-folders"
        }
      ],
      supportRoutes: [
        {
          name: "Tutorials",
          title: "Tutorial",
          path: "/tutorial?ishome=true",
          query: "isHome",
          icon: "ph-book-bookmark"
        }
      ],
      currentRoute: "Home",
      userMenuOpen: false,
      showModal: false
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    userName() {
      const name = this.$msal.data.user.name.split(" ");
      return `${name[0].charAt(0)}. ${name[1]}`;
    },
    userFullName() {
      return this.$msal.data.user.name;
    },
    userEmail() {
      const email = this.$msal.data.user.userName.split("@");
      return `${email[0]}@${email[1]}`;
    }
  },
  mounted() {
    let path = window.location.pathname;
    let x = path.split("/").filter(item => item);
    let currentRoute = {};
    if (x.length > 1) {
      currentRoute = this.menuRoutes.find(item => item.path == `/${x[0]}`);
    }
    if (x.length === 1) {
      currentRoute = this.menuRoutes.find(item => item.path == path);
    }
    this.currentRoute = currentRoute.name ? currentRoute.name : "Home";
  },
  methods: {
    stringToBoolean: function(string) {
      if (!string) {
        return false;
      }
      switch (string.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
          return true;

        case "false":
        case "no":
        case "0":
        case null:
          return false;

        default:
          return Boolean(string);
      }
    },
    changeRoute(route, isName = true) {
      if (route.name === "Tutorials") {
        return (this.openTutorial = true);
      }
      if (isName) {
        this.currentRoute = route.name;
        if (this.$route.name !== route.name) {
          if (route.query) {
            this.$router.push({
              name: route.name,
              query: { home: true }
            });
          }
          if (!route.query) {
            this.$router.push({ name: route.name });
          }
        }
      }
    },
    openUserMenu() {
      this.userMenuOpen = true;
    },
    closeUserMenu() {
      this.userMenuOpen = false;
    },
    onClickOutside() {
      this.closeUserMenu();
    },
    openTutorialModal() {
      this.openTutorial = true;
    },
    logOut() {
      window.localStorage.removeItem("closeModal");
      this.$msal.signOut();
      this.$router.push({ name: "Login" });
    }
  }
};
</script>

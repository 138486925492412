<template>
  <div id="app" class="mh-100 h-100">
    <router-view />
  </div>
</template>

<script>
import PIAxios from "@/utils/PIAxios.js";
export default {
  methods: {
    async loginPI() {
      try {
        let data = {
          username: "admin",
          password: "bt5hW7ccPcXf7CdW",
        };
        let response = await PIAxios.post("/api/auth", data);
        localStorage.setItem("PI-TOKEN", response.token);
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.loginPI();
  },
};
</script>

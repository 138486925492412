<template>
  <div class="home h-100 d-flex justify-content-start">
    <Sidebar ref="sidebar" />
    <div class="content overflow-auto content d-flex flex-column mh-100">
      <div class="flex-grow-1">
        <h5 v-if="!fileUploaded" class="pt-5">NEUES ARTIKELDATENMAPPING</h5>
        <h5 v-if="fileUploaded" class="pt-5">NEUES ARTIKELDATENMAPPING</h5>

        <div v-if="!fileUploaded" class="row vh-80">
          <div
            class="
              col-6
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <div class="main text-left">
              <h1>CSV DATEI HOCHLADEN</h1>
              <p class="text-dark-grey pt-4">
                Korrekte Formatierung beachten. <br />Trennzeichen: Komma und
                Semikolon
              </p>
            </div>
          </div>
          <div class="col-6 m-auto py-2">
            <Uploader
              @cancelReq="cancel"
              ref="uploader"
              :percent="progress"
              :isUploading="startUpload"
              @fileUploaded="atFileUploaded"
            />
          </div>
        </div>

        <div v-if="fileUploaded" class="row vh-80">
          <Wizard ref="wizardMap" :wizardFields="wizardFields" />
        </div>
      </div>

      <div
        v-if="!fileUploaded"
        class="vh-10 d-flex justify-content-start align-items-center"
      >
        <p class="text-dark-grey font-small">Hilfe benötigt?</p>
        <p @click="openTutorial" class="link text-primary ml-2">Tutorial</p>
      </div>

      <div
        v-else-if="fileUploaded"
        class="vh-10 d-flex justify-content-between align-items-center"
      >
        <div
          class="back d-flex align-items-center pointer"
          @click="fileUploaded = false"
        >
          <ph-caret-left :size="18" weight="bold" />
          <span class="link text-primary ml-2">ZURÜCK</span>
        </div>
        <button @click="createMap" class="btn btn-primary">
          <ph-check :size="18" weight="bold" />
          Speichern
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import API_ROUTES from "@/utils/API_ROUTES.js";
import Uploader from "@/components/Uploader.vue";
import Wizard from "@/components/Wizard.vue";
import { mapMutations } from "vuex";
export default {
  name: "Home",
  components: {
    Uploader,
    Wizard
  },
  data() {
    return {
      seperator: null,
      fileUploaded: false,
      wizardFields: [],
      localFile: null,
      progress: 0,
      startUpload: false,
      request: null
    };
  },
  mounted() {
    const data = {
      size: null,
      name: null,
      status: false
    };
    this.$store.commit("setFileInfo", data);
    this.$refs.uploader.cancelUpload();
  },
  methods: {
    ...mapMutations(["setFileInfo"]),
    openTutorial() {
      this.$refs.sidebar.openTutorialModal();
    },

    atFileUploaded(val) {
      this.wizardFields = this.$store.state.wizardFields;
      this.fileUploaded = true;
      this.seperator = val.seperator;
      this.localFile = val.file;
    },
    encodeObjToQuery(obj) {
      let str = [];
      // eslint-disable-next-line no-prototype-builtins

      /* eslint-disable */
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return encodeURIComponent(str.join("&"));
    },
    cancel() {
      this.seperator = null;
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
    },

    uploadFile(file, mappingData) {
      let fileName = file.name;
      this.fileUploaded = false;
      this.startUpload = true;
      this.progress = 0;
      const that = this;
      const formData = new FormData();
      formData.append("file", file);
      console.log(this.encodeObjToQuery(mappingData));
      this.$axios
        .post(
          API_ROUTES.UPLOAD_CSV(
            this.seperator,
            this.encodeObjToQuery(mappingData),
            fileName
          ),
          formData,
          {
            Headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: function(progressEvent) {
              that.progress = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }
          }
        )
        .then(res => {
          if (res) {
            this.$refs.uploader.clear();
            this.$router.push({ name: "Processing" }).catch(err => {});
          }
        })
        .catch(err => {
          const data = {
            errStatus: true,
            errTitle: `Interner Fehler`,
            errDesc: "Interner Fehler"
          };
          if (err.response) {
            if (err.response.data.code === "MAPPING_ERROR") {
              data.errTitle = "Mapping Fehler";
              data.errDesc = "Ungültiges Mapping";
            }
            if (err.response.data.code === "MULTIPLE_CSV_NOT_SUPPORTED") {
              data.errTitle = "Mehrfach CSV nicht unterstützt";
              data.errDesc = "Mehrfache CSV Dateien nicht unterstützt";
            }
            if (err.response.data.code === "HEADERS_VALIDATION_FAILED") {
              data.errTitle = "Header Validierung fehlgeschlagen";
              data.errDesc = "CSV Datei enthält nicht zugeordnete Header";
            }
          }
          this.$refs.uploader.setError(data);
        });
    },
    createMap() {
      const mapData = this.$refs.wizardMap.completeMapping();
      if (mapData) {
        let mappingData = {};
        for (const item of mapData) {
          if (item.val !== "") {
            mappingData[item.key] = item.val;
          }
        }
        this.uploadFile(this.localFile, mappingData);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  .main {
    width: auto;
    p {
      line-height: 24px;
    }
  }
  .link {
    font-size: 14px;
  }
}
</style>

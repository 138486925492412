import axios from 'axios';
import AxiosIsCancel from 'axios/lib/cancel/isCancel';
import router from '../router';
import store from "../store/index";
const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_AZURE_SEARCH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
axiosApi.interceptors.request.use(
  (config) => {
    config.headers['api-key'] = process.env.VUE_APP_AZURE_SEARCH_API_KEY;
    if (typeof config.spinner === 'function') {
      config.spinner(true);
    }
    let source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;
    store.commit('ADD_CANCEL_TOKEN', source);
    return config;
  },
  (error) => {
    
    if (error.constructor.name === 'Cancel') {
      if (typeof error.config.spinner === 'function') {
        error.config.spinner(false);
      }
      return;
    }
    if (error.config && typeof error.config.spinner === 'function') {
      error.config.spinner(false);
    }

    if (error.status === 401) {
      router.push({ name: 'Login' });
    } else {
      console.error(error.message || error);
    }
  }
);

axiosApi.interceptors.response.use(
  (response) => {
    if (typeof response.config.spinner === 'function') {
      response.config.spinner(false);
    }
    if (response.data.error) {
      if (!AxiosIsCancel(response)) {
        console.error(response.data.error.message || response.data.error);
      }
      return;
    }
    return response.data;
  },
  (error) => {
    console.log(error)
    if (error.constructor.name === 'Cancel') {
      if (error.message && typeof error.message.spinner === 'function') error.message.spinner(false);
      return;
    }
    if (error.config && typeof error.config.spinner === 'function') {
      error.config.spinner(false);
    }
    if (!AxiosIsCancel(error)) {
      if (error.response.status === 401) {
        router.push({ name: 'Logout' });
      }
      else {
        console.error(error.message || error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosApi;

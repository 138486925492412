import OpenIndicator from '@/components/OpenIndicator.vue';
import Sidebar from '@/components/Sidebar';
import 'dayjs/locale/de';
import PhosphorVue from "phosphor-vue";
import Vue from 'vue';
import Dayjs from 'vue-dayjs';
import msal from 'vue-msal';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
// use default options
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import App from './App.vue';
import router from './router';
import store from './store';
import axiosApi from './utils/axiosApi';
import searchApi from "./utils/searchApi";
Vue.use(PerfectScrollbar)
//Dayjs.locale('de')
Vue.use(Dayjs, {
  locale: 'de'
});
Vue.prototype.$axios = axiosApi;
Vue.prototype.$search = searchApi;

vSelect.props.components.default = () => ({ OpenIndicator });

require('@/assets/styles/index.scss');

Vue.use(PhosphorVue);
Vue.component("Sidebar", Sidebar);
Vue.component('v-select', vSelect);

Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_MSAD_CLIENT_ID,
    authority: process.env.VUE_APP_MSAD_AUTHORITY,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl:
      process.env.VUE_APP_MSAD_NAV_TO_LOGIN_REQ_URL === 'true',
    requireAuthOnInitialize:
      process.env.VUE_APP_MSAD_REQUIRE_AUTH_ON_INIT === 'true',
      onToken: async (ctx, error, response) => {
        window.localStorage.setItem('accessToken', response.accessToken);
      },
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

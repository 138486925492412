<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container text">
          <div class="d-flex justify-content-start align-items-center">
            <div
              class="
                modal-header
                d-flex
                justify-content-between
                w-100
                align-items-center
              "
            >
              <h3>Tutorials</h3>
              <div v-if="!isExit">
                <ul class="header-menu">
                  <li v-for="(item, index) in menus" :key="index">
                    <a
                      :class="{ active: selectedTab === item.component }"
                      @click.prevent="selectTab(item.component)"
                      href="#"
                    >
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <ph-x
                v-if="!isExit"
                @click="closeHeader"
                :size="24"
                weight="bold"
                class="pointer mt-1"
              />
            </div>
          </div>
          <div v-if="isExit" class="modal-body">
            <div class="isexit">
              <h3 class="isexit-title">Are you sure you want to exit?</h3>
              <div class="pt-5">
                <input
                  v-model="skipNextTime"
                  class="checkbox"
                  type="checkbox"
                  id="show_next_time"
                  value="true"
                />
                <label for="show_next_time">Tutorial nicht mehr anzeigen</label>
              </div>
              <div class="d-flex mt-4">
                <button
                  class="btn btn-white mr-3"
                  @click="
                    isExit = false;
                    skipNetxtTime = false;
                  "
                >
                  Zuruck
                </button>
                <button class="btn btn-primary ml-3" @click="closeModal">
                  Ja
                </button>
              </div>
            </div>
          </div>
          <div v-if="!isExit" class="modal-body">
            <component @tabChange="tabChange" :is="currentTabComponent" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const MenusScreen = () =>
  import("@/components/Modals/TutorialScreen/Menus.vue");
const ImportScreen = () =>
  import("@/components/Modals/TutorialScreen/Import.vue");
const ExportScreen = () =>
  import("@/components/Modals/TutorialScreen/ExportScreen.vue");
const ManualMappingScreen = () =>
  import("@/components/Modals/TutorialScreen/ManualMappingScreen.vue");
const ManuallesMapping = () =>
  import("@/components/Modals/TutorialScreen/ManualMappingScreen.vue");
const ErgebnisseScreen = () =>
  import("@/components/Modals/TutorialScreen/ErgebnisseScreen.vue");
const AutoMappingScreen = () =>
  import("@/components/Modals/TutorialScreen/AutoMappingScreen.vue");
export default {
  components: {
    Menus: MenusScreen,
    Import: ImportScreen,
    Export: ExportScreen,
    ManualMapping: ManualMappingScreen,
    Ergebnisse: ErgebnisseScreen,
    AutoMappingScreen: AutoMappingScreen,
    ManuallesMapping
  },
  data() {
    return {
      skipNextTime: false,
      isExit: false,
      selectedTab: "Menus",
      menus: [
        {
          name: "Menü",
          component: "Menus"
        },
        {
          name: "Import",
          component: "Import"
        },
        {
          name: "Ergebnisse",
          component: "Ergebnisse"
        },
        {
          name: "Export",
          component: "Export"
        },
        {
          name: "Auto-Mapping",
          component: "AutoMappingScreen"
        },
        {
          name: "Manuelles Mapping",
          component: "ManuallesMapping"
        }
      ]
    };
  },
  computed: {
    currentTabComponent() {
      return this.selectedTab;
    }
  },
  methods: {
    stringToBoolean: function(string) {
      if (!string) {
        return false;
      }
      switch (string.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
          return true;

        case "false":
        case "no":
        case "0":
        case null:
          return false;

        default:
          return Boolean(string);
      }
    },
    closeHeader() {
      this.$emit("close");
    },
    selectTab(key) {
      this.selectedTab = key;
    },
    closeModal() {
      this.$emit("close");
    },
    tabChange(val) {
      if (!val) {
        const currentIndex = this.menus.findIndex(
          item => item.component === this.selectedTab
        );
        if (currentIndex !== -1) {
          this.selectedTab = this.menus[currentIndex + 1].component;
        }
      }
      if (val) {
        const currentIndex = this.menus.findIndex(
          item => item.component === this.selectedTab
        );
        console.log(currentIndex);
        if (currentIndex !== -1) {
          this.selectedTab = this.menus[currentIndex - 1].component;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 127, 0, 0.9);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  * {
    font-family: Barlow;
  }
}
.isexit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.isexit-title {
  font-size: 25px;
  text-align: center;
}
.modal-container {
  width: 1140px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
  border-radius: 5px;
}

.modal-header h3 {
  margin-top: 0;
  font-family: Barlow Condensed;
  color: #ee7f00;
  font-size: 16px;
}

.modal-body {
  margin: 20px 0;

  p {
    font-size: 14px;
  }

  input {
    padding: 10px;
  }

  textarea {
    resize: vertical;
  }

  textarea::-webkit-resizer {
    background-size: contain;
    background-image: url(../../assets/icons/Resizer.svg);
  }

  .form-control::placeholder {
    font-size: 16px;
    color: #909090;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.header-menu {
  display: flex;
  background: #f0f0f0;
  padding: 5px;
  border-radius: 23px;
  li {
    list-style: none;
    a {
      display: block;
      padding: 5px 23px;
      color: #909090;
      font-weight: bold;
      text-decoration: none;
      &.active {
        background: #fff;
        color: #555555;
        border-radius: 18px;
      }
    }
  }
}
</style>
export default {
  /** @method post */
  UPLOAD_CSV(seperator, mapping, fileName) {
    return `uploadCSV?separator=${seperator}&mapping=${mapping}&fileName=${fileName}`;
  },
  /** @method get */
  getTaskList(status, page) {
    return `task/history?status=${status}&page=${page}&type=progress`;
  },
  /** @method get */
  getTaskDetail(id) {
    return `task/status?resource_id=${id}`;
  },
  getTaskResult(id) {
    return `task/result?resource_id=${id}&type=fast`;
  },
  getTaskResultSlow(id) {
    return `task/result?resource_id=${id}&type=fast`;
  },
  getHistoryList(status, page) {
    return `task/history?status=${status}&page=${page}`;
  },
  sendSupportMessage() {
    return `support/contact`;
  },
  cancelTask() {
    return `task/cancel`;
  },
  search(keyword) {
    return `${keyword}&$select=Attributes,modellname,lieferant_name&$filter=status ne 'New' and status ne 'InProcess' and status ne 'Releaseable' and status ne 'InValidation2' and status ne 'Inactive' and status ne 'Obsolete'`;
  },
  taskResult(id) {
    return `task/result?resource_id=${id}&type=fast`;
  },
  klarFallExport(taskID) {
    return `task/process-type-a?taskId=${taskID}`;
  },
  autoMappingExport(taskID) {
    return `task/process-type-b?taskId=${taskID}`;
  },
  getProgress(taskID) {
    return `userManualMappingProgress/my?resource_id=${taskID}`;
  },
  saveProgress() {
    return `userManualMappingProgress`;
  },
};

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wizardFields: [],
    fileInfo: {
      size: null,
      name: null,
      status: false,
      
    },
    cancelTokens: []
  },
  mutations: {
    setWizardFields(state, payload) {
      state.wizardFields = payload;
    },
    setFileInfo(state, payload) {
      state.fileInfo = payload;
    },
    ADD_CANCEL_TOKEN(state, token){
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
  }
  },
  getters: {
    getFileInfo(state) {
      return state.fileInfo
    }
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {

      // eslint-disable-next-line
      context.state.cancelTokens.forEach((request, i) => {
        request.cancel();
      });

      // Reset the cancelTokens store
      context.commit('CLEAR_CANCEL_TOKENS');
  }
  },
  modules: {
  }
})

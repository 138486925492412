import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/auth/Logout.vue"),
  },
  {
    path: "/tutorial",
    name: "Tutorials",
    component: () =>
      import(/* webpackChunkName: "tutorial" */ "../views/Tutorials.vue"),
  },
  {
    path: "/history",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/History.vue"),
  },
  {
    path: "/processing",
    name: "Processing",
    component: () =>
      import(/* webpackChunkName: "processing" */ "../views/Processing.vue"),
  },
  {
    path: "/processing/empty-mapping",
    name: "ManualMapping",
    component: () =>
      import(
        /* webpackChunkName: "processing" */ "../views/manualMapping/EmptyMapping.vue"
      ),
  },
  {
    path: "/processing/auto-mapping",
    name: "AutoMapping",
    component: () =>
      import(
        /* webpackChunkName: "processing" */ "../views/autoMapping/AutoMapping.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.public || Vue.prototype.$msal.isAuthenticated()) {
    next();
  } else {
    next("/login");
  }
});

export default router;

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container text" v-click-outside="onClickOutside">
          <div class="d-flex justify-content-end align-items-center">
            <ph-x
              @click="onClickOutside"
              :size="24"
              weight="bold"
              class="pointer"
            />
          </div>

          <div class="modal-header">
            <h3>IMPORT abbrechen?</h3>
          </div>

          <div class="relative">
            <div class="modal-footer pt-4 mt-4">
              <button class="mr-4 action btn btn-primary" @click="cancelUpload">
                Ja
              </button>
              <button class="action btn btn-primary" @click="onClickOutside">
                Nein
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  name: "SupportModal",
  props: {
    shownModal: Boolean,
  },
  data() {
    return {
      showModal: this.shownModal,
      canceled: false,
      form: {
        title: null,
        body: null,
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    cancelUpload() {
      this.$emit("cancel");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  * {
    font-family: Barlow;
  }
}

.modal-container {
  width: 650px;
  min-height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: auto;
  border-radius: 5px;
}

.modal-header h3 {
  margin-top: 0;
  font-family: Barlow Condensed;
}

.modal-body {
  margin: 20px 0;

  p {
    font-size: 14px;
  }

  input {
    padding: 10px;
  }

  textarea {
    resize: vertical;
  }

  textarea::-webkit-resizer {
    background-size: contain;
    background-image: url(../../assets/icons/Resizer.svg);
  }

  .form-control::placeholder {
    font-size: 16px;
    color: #909090;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>